<template>
    <div class="card">
        <div class="title">{{content.name}}</div>
        <div class="bottom">剩余存货{{content.stock}}个</div>
    </div>
</template>
<script>
export default {
    props:[
        "content"
    ],
    data() {
        return{
            data:{
                title:'喜马拉雅会员月卡',
                surplus:10
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .card{
        width: 6.68rem;
        height: 3.5rem;
        background: url('../assets/bg.png') no-repeat;
        background-size: 100%;
        margin: auto;
        margin-top:0.2rem ;
        margin-bottom:0.2rem ;
        font-size: 0.24rem;
        position: relative;
        display: flex;
        align-items: center;
        color: #C90909;
        justify-content: center;
        .title{
          font-size: .4rem;
        }
        .bottom{
            position: absolute;
            bottom: 10%;
            right: 5%;
           
        }
    }
</style>