<template>
    <div class="exchange">
        <card :content="data"></card>
         <div class="inner-input">
          <div>
                <input type="number" v-model="number" placeholder="输入兑换的手机号码"/>
              <p class="item">*兑换后无法撤销，请确认输入的号码信息</p>
            </div>
          <div><button @click="exchange">立即兑换</button></div>
      </div>
     <div>
         <div class="explain">
          <div style="text-align:center;"><img src="../assets/head2.png"/></div>
          <div v-html="data.detail"></div>
      </div>
    </div>
      <Dialog2 v-show="showP" :code="goodscode"></Dialog2>
    </div>
</template>
<script>
import card from '@/components/card'
import Dialog2 from '@/components/Dialog2'
import {goodsinfo, exchange} from '@/api'
export default {
    name:'Exchange',
    components:{
        card,
        Dialog2
    },
    data() {
        return{
            number:'',
            showP:false,
            data:{},
            goodscode:"",
        }
    },
    methods:{
      async getgoods(){
         const result = await goodsinfo({goods_id:this.$route.query.id})
         console.log(result)
        this.data = result.data.data.goods_info
      },
      async exchange(){
          if(this.number === "") return
          const result = await exchange({
            goods_id:this.$route.query.id,
            coupon:sessionStorage.getItem("counpon"),
            phone:this.number
          })
          
          if(result.data.data.status){
               this.showP = true
               this.goodscode = result.data.data.coupon_info.goods_sn
          }else{
              this.$toast(result.data.data.message)
          }
      }
    },
    created() {
      this.getgoods()
    }
}
</script>
<style lang="scss" scoped>
  .exchange{
    font-size: 0.24rem;
  }
    .inner-input{
        width: 6.26rem;
        margin: auto;
        margin-top: 0.5rem;
        margin-bottom:0.5rem;
         font-size: 0.22rem;
      input{
        width: 5.86rem;
        height: .72rem;
        border:1px solid #707070;
        color: #999999;
        padding: 0 .2rem;
        border-radius: 5px;
      }
      button{
        @extend input;
        width: 6.26rem;
        margin: 0.2rem 0;
        background: #E84149;
        color: white;
        border-radius: 30px;
        border:none;
      }
      .item{
        margin: 0.02rem 0;
        font-size: 0.22rem;
        color: #E84149;
      }
    }
    .explain{
      text-align: center;
      padding: 0.2rem;
      margin-top: 0.3rem;
      font-size: 0.24rem;
      color: #666666;
      letter-spacing: 0.2px;
      line-height:.4rem;
      text-align: left;
      img{
        width: 2.8rem;
        height: 0.35rem;
      } 
    }
   
</style>